<template>
  <div class="direct-frame">
    <iframe
      v-if="src !== undefined"
      class="direct-frame-item"
      :src="src"
    ></iframe>
    <div class="wrap" v-else></div>
  </div>
</template>

<script>
import constants from "@/common/constants.js";

export default {
  data() {
    return {
      src: undefined,
    };
  },

  methods: {
    createLink(queries) {
      let endpoint = constants.baseURL;
      // placementId
      const placementId = `?placementId=${
        queries.placementId ?? constants.defaultPlacementId
      }`;
      // wallet
      const wallet = queries.wallet ? `&wallet=${queries.wallet}` : "";
      // clientOrderId
      const clientOrderId = queries.clientOrderId
        ? `&clientOrderId=${clientOrderId}`
        : "";

      // calculatorValues
      const calculatorValues = {};

      if (queries.fiatCurrency) {
        calculatorValues.fiatCurrency = queries.fiatCurrency;
      }

      if (queries.fiatValue) {
        calculatorValues.fiatValue = queries.fiatValue;
      }

      if (queries.cryptoCurrency) {
        calculatorValues.cryptoCurrency = queries.cryptoCurrency;
      }

      if (queries.cryptoValue) {
        calculatorValues.cryptoValue = queries.cryptoValue;
      }

      const calcQuery = `&calculatorValues=${JSON.stringify(calculatorValues)}`;

      // endpoint build
      endpoint += placementId + calcQuery + wallet + clientOrderId;
      this.src = endpoint;
    },
  },

  mounted() {
    const route = this.$route;
    const queries = {
      placementId: route.query.placementId,
      fiatCurrency: route.query.fiatCurrency,
      fiatValue: route.query.fiatValue,
      cryptoCurrency: route.query.cryptoCurrency,
      cryptoValue: route.query.cryptoValue,
      wallet: route.query.wallet,
      clientOrderId: route.query.clientOrderId,
    };
    this.createLink(queries);
  },
};
</script>

<style lang="sass" scoped>
.direct-frame
  position: relative
  display: block
  width: 100%
  margin: 0
  padding: 0
  min-height: 100vh

  &-item
    position: absolute
    top: 0
    left: 0
    bottom: 0
    right: 0
    width: 100%
    height: 100%
    border: 0

.wrap
  display: flex
  flex-flow: row nowrap
  justify-content: center
  align-items: center
</style>
