<template>
  <div class="home">
    <DirectFrame />
  </div>
</template>

<script>
import DirectFrame from "@/components/DirectFrame.vue";

export default {
  name: "Home",
  components: {
    DirectFrame,
  },
};
</script>
