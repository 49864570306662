<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: "Cyclebit",
    titleTemplate: "%s | Buy",
    htmlAttrs: {
      lang: "en-US",
    },
    meta: [
      {
        name: "description",
        content: "Cyclebit | Buy",
      },
      {
        name: "msapplication-TileColor",
        content: "#9013fe",
      },
      {
        name: "theme-color",
        content: "#ffffff",
      },
    ],
    link: [
      {
        rel: "apple-touch-icon",
        sizes: "180x180",
        href: "/apple-touch-icon.png",
      },
      {
        rel: "icon",
        type: "image/png",
        sizes: "32x32",
        href: "/favicon-32x32.png",
      },
      {
        rel: "icon",
        type: "image/png",
        sizes: "16x16",
        href: "/favicon-16x16.png",
      },
      {
        rel: "manifest",
        href: "/site.webmanifest",
      },
      {
        rel: "mask-icon",
        href: "/safari-pinned-tab.svg",
        color: "#9013fe",
      },
    ],
  },
};
</script>

<style lang="sass">
@import "~normalize.css/normalize.css"

*,
*::before,
*::after
  box-sizing: border-box

*:focus
  outline: none

*
  -moz-osx-font-smoothing: grayscale
  -webkit-font-smoothing: antialiased

body
  background-color: #ffffff
</style>
